import { RouteRecordRaw } from 'vue-router'

const routes:RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('../views/page.vue'),
    },
    {
        path: '/career',
        component: () => import('../views/career/page.vue'),
    },
    {
        path: '/career/:slug',
        component: () => import('../views/career/slug/page.vue'),
    },
    {
        path: '/faq',
        component: () => import('../views/faq/page.vue'),
    },
    {
        path: '/contact-us',
        component: () => import('../views/contact/page.vue'),
    },
    {
        path: '/services',
        component: () => import('../views/services/page.vue'),
    },
    {
        path: '/services/web-development',
        component: () => import('../views/services/slug/WebDevelopment.vue'),
    },
    {
        path: '/services/digital-marketing',
        component: () => import('../views/services/slug/DigitalMarketing.vue'),
    },
    {
        path: '/services/ui-ux',
        component: () => import('../views/services/slug/UiUx.vue'),
    },
    {
        path: '/services/seo',
        component: () => import('../views/services/slug/Seo.vue'),
    },
    {
        path: '/services/app-development',
        component: () => import('../views/services/slug/AppDevelopment.vue'),
    },
    {
        path: '/services/it-consulting',
        component: () => import('../views/services/slug/ItConsultant.vue'),
    },
    {
        path: '/services/software-development',
        component: () => import('../views/services/slug/SoftwareDevelopment.vue'),
    },
    {
        path: '/services/web-scraping',
        component: () => import('../views/services/slug/WebScraping.vue'),
    },
    {
        path: '/projects',
        component: () => import('../views/projects/page.vue'),
    },
    {
        path: '/about-us',
        component: () => import('../views/about-us/page.vue'),
    },
    {
        path: '/:catchAll(.*)*',
        name: '404',
        component: () => import('../views/404.vue'),
    },
]

export default routes
