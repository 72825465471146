<template>
  <div class="lg:h-[95px] h-[65px] w-full bg-[#F7F8FD]  fixed"
  :class="isActive ? 'z-[9999]':'z-[9]'"
  >
    <header
      :class="isOpen ? 'menu_bar' : ''"
      class="flex gap-2 lg:justify-between lg:items-center sm:py-3 main lg:h-[95px] h-[65px] items-center safe-area"
    >
      <div
        @click="router.push('/')"
        class="flex items-center cursor-pointer justify-between sm:p-0 order-1 lg:order-2 col-span-4 sm:col-span-5"
      >
        <img
          class="lg:h-[48px] lg:w-[170px] w-fit h-[28px] object-contain"
          src="https://i.postimg.cc/7hNh0LqQ/detech-logo.webp"
          alt="logo"
          height="48"
          width="170"
        />
      </div>
      <div class="lg:hidden order-3 flex justify-end col-span-1">
        <button
          @click="isOpen = !isOpen"
          class="grid box-content grid-cols-[1fr] w-[20px] m-0 p-2 rounded-full border-[none] bg-none cursor-pointer gap-[5px] menu-control"
          aria-label="Toggle mobile menu"
        >
          <span
            :class="isOpen ? 'rotate-45' : 'rotate-0'"
            class="w-full h-[2px] origin-[0%_50%] [transition:0.2s_cubic-bezier(0.34,_1.56,_0.64,_1)] rounded-full bg-black"
          ></span>
          <span
            :class="isOpen ? 'opacity-0' : 'opacity-100'"
            class="w-[75%] h-[2px] origin-[0%_50%] [transition:0.2s_cubic-bezier(0.34,_1.56,_0.64,_1)] rounded-full bg-black"
          ></span>
          <span
            :class="isOpen ? '-rotate-45 !w-full' : 'rotate-0'"
            class="w-[50%] h-[2px] origin-[0%_50%] [transition:0.2s_cubic-bezier(0.34,_1.56,_0.64,_1)] rounded-full bg-black"
          ></span>
        </button>
      </div>
      <div
        class="order-2 col-span-2 menubar "
        :class="[isOpen ? 'menubar--open' : 'menubar--closed']"
      >
        <nav
          class="flex justify-center items-center md:h-[3rem] md:px-[2rem] menubar__wrapper md:gap-x-[2.5rem] md:gap-y-[3rem]"
        >
          <router-link
            v-for="(link, index) in nav_links"
            :key="index"
            @click.native="isOpen = false"
            :to="link.link"
            :class="[
              'md:flex decoration-none leading-[1] w-full text-[18px] md:mb-[-3px] items-stretch text-black gap-x-[2px] gap-y-[2px] max-w-[100%] font-medium relative lg:after:content-[\'\'] lg:after:absolute lg:after:h-[2px] lg:after:w-0 lg:after:bottom-[-2px] lg:hover:after:w-full lg:after:bg-[#4B5563] lg:after:duration-500 lg:after:ease-in-out lg:after:transition-all menubar__link py-2',
              { 'active-link': isCurrentRoute(link.link) }
            ]"
          >
            <div
              class="[transform:translate3d(0px,_0%,_0px)_scale3d(1,_1,_1)_rotateX(0deg)_rotateY(0deg)_rotateZ(0deg)_skew(0deg,_0deg)] text-nowrap [transform-style:preserve-3d]"
            >
              {{ link.name }}
            </div>
          </router-link>
        </nav>
      </div>
      <router-link
        to="/contact-us"
        class="primary-btn order-2 lg:order-3 sm:col-span-3 col-span-4 text-[14px] inline-block text-nowrap max-w-[200px] lg:mx-0 ml-auto"
      >
        <span class="font-medium">Contact us</span>
      </router-link>
    </header>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, onBeforeUnmount, Ref } from "vue";
import { useRouter, useRoute } from "vue-router";
const isOpen: Ref<boolean> = ref(false);
const isActive: Ref<boolean> = ref(false);
const threshold = 50;
const router = useRouter();
const route = useRoute();

interface Social {
  name: string;
  link: string;
  icons?: string;
}

const nav_links = computed<Social[]>(() => {
  return [
    { name: "Home", link: "/", icons: "" },
    { name: "Services", link: "/services", icons: "" },
    { name: "Projects", link: "/projects", icons: "" },
    { name: "About Us", link: "/about-us", icons: "" },
    { name: "FAQs", link: "/faq", icons: "" },
    { name: "Career", link: "/career", icons: "" },
  ];
});

const handleScroll = () => {
  const currentScrollTop = window.scrollY;
  isActive.value = currentScrollTop >= threshold;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  handleScroll();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
const isCurrentRoute = (link: string) => {
  return route.path === link || route.path.startsWith(link + '/');
};

</script>

<style lang="scss" scoped>
.smooth-transition {
  transition: background-color 0s ease;
}
.menubar {
  @media (max-width: 1023px) {
    position: absolute;
    top: 4rem;
    bottom: auto;
    right: 0;
    left: auto;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out,
      width 0.5s ease-in-out;
    width: 100vw;
    max-width: 7670px;
    max-height: 0;
    height: 100vh;
    z-index: 999;
    @apply bg-[#F7F8FD];
    &.menubar--closed {
      max-height: 0;
      padding: 0;
    }

    &.menubar--open {
      max-height: 900px;
      height: 100vh;
      width: 100vw;
      padding: 1rem;
    }
    .menubar__wrapper {
      position: static;
      display: grid;
      padding: 1rem;
      height: auto;
      justify-items: flex-start;
      align-items: start;
      justify-content: flex-start;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      gap: 2rem;
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0;
        gap:1rem;
      }
      .menubar__link {
        font-size: 1.5rem;
        overflow: hidden;
      }
    }
  }
}

.active-link {
  &::after {
    width: 100% !important;
  }
}
</style>