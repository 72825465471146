import { ViteSSG } from 'vite-ssg';
import './index.scss';
import App from './App.vue';
import router from './router';
import posthogPlugins from './plugins/posthog.js';
export const createApp = ViteSSG(
    App,
    {
        routes: router.options.routes,
        base: import.meta.env.BASE_URL,
        history: router.options.history,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else if (to.hash) {
                return {
                    el: to.hash,
                    behavior: 'smooth',
                    top: 95,
                };
            } else {
                return { top: 0 };
            }
        },
    },
    ({ app }) => {
        app.use(posthogPlugins)
    }
);
