<script setup lang="ts">
import Navbar from "@/components/common/Navbar.vue";
import Footer from "@/components/common/Footer.vue";
import Loading from "@/components/common/Loading.vue";
import WhatsApp from "@/components/common/Whatsapp.vue";
import { onMounted, ref } from "vue";

const isLoading = ref(true);

const handleHideLoading = () => {
  const minLoadingTime = 1000;
  const startTime = Date.now();

  return new Promise<void>((resolve) => {
    setTimeout(() => {
      const elapsed = Date.now() - startTime;
      const delay = Math.max(minLoadingTime - elapsed, 0);

      setTimeout(() => {
        isLoading.value = false;
        resolve();
      }, delay);
    }, 0);
  });
};

const isSSG = typeof window === "undefined";

if (!isSSG) {
  onMounted(() => {
    handleHideLoading();
  });
}
</script>

<template>
  <Loading :loading="isLoading || isSSG" />
  <section class="min-h-[100vh]" v-if="!isLoading || isSSG">
    <Navbar />
    <WhatsApp />
    <main class="lg:pt-[95px] pt-[65px]">
        <router-view />
    </main>
    <Footer />
  </section>
</template>

<style scoped lang="scss">
body::-webkit-scrollbar {
  @apply w-[1px];
}
body::-webkit-scrollbar-track {
  @apply bg-gray-100;
}
body::-webkit-scrollbar-thumb {
  @apply bg-primary;
}
</style>

