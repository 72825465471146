<template>
  <footer class="bg-[#2A2A2A] text-white py-12 px-4 md:px-8">
    <div class="max-w-7xl mx-auto">
      <!-- Logo and Social Section -->
      <div
        class="flex flex-col md:flex-row md:justify-between md:items-start mb-8 gap-8"
      >
        <div class="mb-8 md:mb-0">
          <img
            src="https://i.postimg.cc/7hNh0LqQ/detech-logo.webp"
            alt="Detech Solution Pvt Ltd"
            class="h-16 mb-6"
          />
          <div class="flex gap-4">
            <a
              href="https://www.instagram.com/detechsolution/"
              target="_blank"
              class="text-gray-400 hover:text-gray-200 transition-colors"
            >
              <InstagramIcon class="h-6 w-6" />
            </a>
            <a
              href="https://www.facebook.com/detechsolution.np/"
              target="_blank"
              class="text-gray-400 hover:text-gray-200 transition-colors"
            >
              <FacebookIcon class="h-6 w-6" />
            </a>
            <a
              href="https://www.linkedin.com/company/detech-solution-np/"
              target="_blank"
              class="text-gray-400 hover:text-gray-200 transition-colors"
            >
              <LinkedinIcon class="h-6 w-6" />
            </a>
          </div>
        </div>

        <!-- Mobile Accordion -->
        <div class="md:hidden space-y-4">
          <div v-for="(section, index) in sections" :key="index">
            <button
              @click="toggleSection(index)"
              class="w-full flex justify-between items-center py-2 text-left text-lg font-semibold"
            >
              {{ section.title }}
              <ChevronDownIcon
                class="h-5 w-5 transition-transform duration-200"
                :class="{ 'rotate-180': openSections[index] }"
              />
            </button>
            <div v-show="openSections[index]" class="mt-2 space-y-2 pl-4">
              <template
               v-for="link in section.links"
                :key="link.text"
              >
              <router-link
                v-if="link.url"
                :to="link.url"
                class="text-gray-300 hover:text-gray-400 flex gap-2"
              >
                <component :is="link.icon" class="text-gray-300" />
                <div>
                  <span class="block">
                    {{ link.text }}
                  </span>
                  <span class="block">
                    {{ link.text_2 }}
                  </span>
                </div>
              </router-link>
              <a
                  v-else-if="link.links"
                  :href="link.links"
                  class="text-gray-300 hover:text-gray-400 cursor-pointer flex gap-2"
                >
                  <component :is="link.icon" class="text-gray-300" />
                  <div>
                    <span class="block">
                      {{ link.text }}
                    </span>
                    <span class="block">
                      {{ link.text_2 }}
                    </span>
                  </div>
                </a>

              </template>
            </div>
          </div>
        </div>

        <!-- Desktop Columns -->
        <div class="hidden md:grid md:grid-cols-3 md:gap-16">
          <div v-for="section in sections" :key="section.title">
            <h2 class="text-lg font-semibold mb-4">{{ section.title }}</h2>
            <ul class="space-y-2">
              <li v-for="link in section.links" :key="link.text">
                <router-link
                  v-if="link.url"
                  :to="link.url"
                  class="text-gray-300 hover:text-gray-400 flex gap-2"
                >
                  <component :is="link.icon" class="text-gray-300" />
                  <div>
                    <span class="block">
                      {{ link.text }}
                    </span>
                    <span class="block">
                      {{ link.text_2 }}
                    </span>
                  </div>
                </router-link>
                <a
                  v-else-if="link.links"
                  :href="link.links"
                  class="text-gray-300 hover:text-gray-400 cursor-pointer flex gap-2"
                >
                  <component :is="link.icon" class="text-gray-300" />
                  <div>
                    <span class="block">
                      {{ link.text }}
                    </span>
                    <span class="block">
                      {{ link.text_2 }}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Copyright -->
      <div
        class="pt-8 mt-8 border-t border-[#666666] text-center text-gray-300"
      >
        <p>
          ©{{ new Date().getFullYear() }} Detech Solution Pvt Ltd. All rights
          reserved
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from "vue";
import {
  TwitterIcon,
  InstagramIcon,
  FacebookIcon,
  LinkedinIcon,
  ChevronDownIcon,
  PhoneIcon,
  MailIcon,
} from "lucide-vue-next";

const sections = [
  {
    title: "PAGES",
    links: [
      { text: "Home", url: "/" },
      { text: "Services", url: "/services" },
      { text: "Projects", url: "/projects" },
      { text: "About Us", url: "/about-us" },
      { text: "FAQs", url: "/faq" },
      { text: "Career", url: "/career" },
    ],
  },
  {
    title: "SERVICES",
    links: [
      { text: "Web Development", url: "/services/web-development" },
      { text: "SEO", url: "/services/seo" },
      { text: "UI/UX Design", url: "/services/ui-ux" },
      { text: "Digital Marketing", url: "/services/digital-marketing" },
      { text: "IT Consulting", url: "/services/it-consulting" },
      { text: "Software Development", url: "/services/software-development" },
    ],
  },
  {
    title: "CONTACT US",
    links: [
      {
        text: "Email:",
        text_2: "contact@detech.com.np",
        links:"mailto:contact@detech.com.np",
        icon: MailIcon,
      },
      {
        text: "Phone:",
        text_2: "+977 981-800-0015",
        links:"tel:+9779818000015",
        icon: PhoneIcon,
      },
    ],
  },
];

const openSections = ref(Array(sections.length).fill(false));

const toggleSection = (index) => {
  openSections.value[index] = !openSections.value[index];
};
</script>